import Vue from "vue";
import { i18n } from "@/locales/i18n";
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
import locales from "@/assets/locales.json";
import countries from "i18n-iso-countries"
import languages from "@cospired/i18n-iso-languages"

// var localizedFormat = require("dayjs/plugin/localizedFormat");
// dayjs.extend(localizedFormat);

Vue.mixin({
  methods: {
    errorSnackbar(error) {
      this.$store.state.snackbar = {
        text: error,
        color: "error",
        timeout: 10000,
        open: true
      };
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    distinct(array) {
      return [...new Set(array)];
    },

    distinctByField(array, field) {
      field = field || "id"
      let result = []
      for (let item of array) {
        const ids = result.map(p => p[field])
        if (!ids.includes(item[field]))
          result.push(item)
      }
      return result
    },

    // It takes an array of normal objects
    arrToHash(arr, key) {
      key = key || "_id";
      if (arr.length == 0) return {};
      if (typeof arr[0] !== "object") return {};
      const result = {};
      arr.forEach(p => (result[p[key]] = this.omit(p, key)));
      return result;
    },

    // Creates an array of normal objects
    hashToArr(hash, key) {
      key = key || "_id";
      const hashKeys = Object.keys(hash);
      if (hashKeys.length == 0) return [];
      if (typeof hash[hashKeys[0]] !== "object") return [];
      const result = hashKeys.map(p => ({ [key]: p, ...hash[p] }));
      return result;
    },

    //clone object except defined keys
    omit(obj, omitKey) {
      return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
          result[key] = obj[key];
        }
        return result;
      }, {});
    },

    async checkJob(jobId) {
      return new Promise(resolve => {
        callAPI({
          url: `${this.$store.state.report_manager_api}/jobs/${jobId}`,
          method: "GET"
        })
          .then(response => {
            resolve(response.data.status.split(".").pop());
          })
          .catch(error => {
            this.$store.state.snackbar = {
              text: error,
              color: "error",
              timeout: 10000,
              open: true
            };
          });
      });
    },

    /* helpers */
    async askForData(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async getUserName(url) {
      if (!url) return "";
      url += `/attrs/name`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getIdentities() {
      const result = await callBffAPI({
        url: "/identities",
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getIdentity(identityId) {
      const url = `/identities?identityId=${identityId}&r8sFields=ownedBy.name,createdBy.name,updatedBy.name,mfUser.username`;
      const method = "GET";
      const result = await callBffAPI({ url, method });
      if (!result.data?.length) return null;
      return result.data[0];
    },

    async getIdentityProfiles(identityId) {
      const url = `${this.$store.state.idp_api_url}/identities/${identityId}/profiles`;
      const method = "GET";
      const result = await callAPI({ url, method });
      if (result == null) return null;
      return result.data;
    },

    async getScope(url, additionalParams) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url
          }/scopes?filter=_uri:in:[${encodeURIComponent(url)}]${additionalParams ? additionalParams : ""
          }`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getClient(url, additionalParams) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url
          }/clients?filter=_uri:in:[${encodeURIComponent(url)}]${additionalParams ? additionalParams : ""
          }`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getResourceServer(url, additionalParams) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url
          }/resource-servers?filter=_uri:in:[${encodeURIComponent(url)}]${additionalParams ? additionalParams : ""
          }`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    getStatusColor(status) {
      let statusTail = "";
      status.includes(".")
        ? (statusTail = status.split(".").pop())
        : (statusTail = status);
      switch (statusTail) {
        case "success":
          return "green";
        case "failed":
          return "red";
        case "purged":
        case "deleted":
        case "inactive":
          return "grey";
        case "active":
        case "processed":
          return "blue";
        case "pending":
          return "orange";
        default:
          return "yellow";
      }
    },

    async calculatePropertiesTokens(items) {
      for (const item of items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = await this.getUserName2(item.createdBy);
      }
    },

    async getUserName2(url) {
      if (!url) return "";
      const result = await callAPI({ url, method: "GET", cache: true });
      if (result.status !== 200) return "";
      if (!result.data) return "";
      return result.data.name;
    },

    createAPIStream(url, initialPage = 0, limit = 20) {
      function _stream(_url, _initialPage, _limit) {
        return new Promise(resolve => {
          callBffAPI({
            url: `${_url}${_url.includes("?") ? "&" : "?"
              }page=${_initialPage}&limit=${_limit}`,
            method: "GET",
            cache: true
          })
            .then(result => {
              _initialPage++;
              resolve({
                data: result.data,
                next() {
                  return _stream(_url, _initialPage, _limit);
                }
              });
            })
            .catch(error => {
              resolve({
                data: [],
                error: error
              });
            });
        });
      }
      return () => _stream(url, initialPage, limit);
    },

    massDataLoader(storeHere, url, initialPage = 0, limit = 20) {
      return new Promise(resolve => {
        const stream = this.createAPIStream(url, initialPage, limit);
        async function _take(_stream) {
          const { data, next } = await _stream();
          data.forEach(item => {
            storeHere.push(item);
          });
          if (data.length < limit) {
            resolve();
            return;
          }
          return _take(next);
        }
        return _take(stream);
      });
    },

    getLocaleDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).format("YYYY-MM-DD hh:mm:ss");
    },

    async getUsers() {
      const result = await callBffAPI({
        url: `/identities?ownedBy=${this.$store.state.user.ownedBy}&idType=user&fields=identityId,name,_uri`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getCustomers() {
      const result = await callBffAPI({
        url: `/customers?sort=name:asc&fields=customerId,name,_uri`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    getDefaultLocale() {
      let locale = localStorage.getItem("locale");
      if (locale == "null") locale = null;
      if (!locale && this.$store && this.$store.state.user) {
        locale = this.$store.state.user.locale;
      }
      if (!locale) {
        locale = "en_US";
      }
      return locale;
    },

    getDefaultLocaleShort() {
      const defaultLocale = this.getDefaultLocale();
      return defaultLocale.substring(0, 2)
    },

    getAllLocales() {
      let defaultLocale = this.getDefaultLocaleShort();
      if (defaultLocale == "nb") defaultLocale = "no"
      const result = locales.map(p => ({ value: p, localeShort: p.substring(0, 2), text: `${languages.getName(p.substring(0, 2), defaultLocale)} (${this.getCountryName(p.substring(3))})` }))
      return result
    },

    getLocaleName(locale) {
      let defaultLocale = this.getDefaultLocaleShort();
      if (defaultLocale == "nb") defaultLocale = "no"
      return `${languages.getName(locale.substring(0, 2), defaultLocale)} (${this.getCountryName(locale.substring(3))})`
    },

    getAllCountries() {
      const defaultLocale = this.getDefaultLocaleShort();
      const _countries = countries.getNames(defaultLocale, { select: "official" })
      const result = this.hashToArrOptions(_countries)
      return result
    },

    getCountryName(countyCode) {
      const defaultLocale = this.getDefaultLocaleShort();
      return countries.getName(countyCode, defaultLocale, { select: "official", })
    },

    getDefaultTimeZone() {
      let timezone = localStorage.getItem("timezone");
      if (timezone == "null") timezone = null;
      const user = this.$store.state.user;
      if (!timezone && this.$store && this.$store.state.user) {
        timezone = this.$store.state.user.timezone;
      }
      if (!timezone) {
        timezone = "GMT";
      }
      return timezone;
    },

    async getAppCodes() {
      try {
        const result = await callAPI({
          //   url: `/resource-servers?filter=status:eq:datasource.status.active&sort=name:asc`,
          // url: `/clients?fields=appCode,client_name&limit=1000`, // TODO: use this after backend bugfix (error 500)
          url: `/clients?limit=1000`,
          method: "GET",
          cache: true
        });
        let appCodes = result.data.filter(p => p.appCode).map(p => ({
          text: `${p.client_name} (${p.appCode})`,
          value: p.appCode
        }));
        appCodes.unshift({  //it adds "_backend" option as first option
          text: "_backend",
          value: "_backend"
        })
        let appCodesHash = this.arrToHash(this.appCodes, "value")
        return { appCodes, appCodesHash }
      }
      catch (error) {
        this.errorSnackbar(error);
      }
    }
  }
});
