import Vue from "vue";
import { apiOpsBff, memory, memoryPrivate, usersBff } from "ngt-frontend-core";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { i18n } from "@/locales/i18n";
import PortalVue from "portal-vue";
import VueObserveVisibility from "vue-observe-visibility";
import "@/filters/commonDataFilters";
import "@/mixins/commonDataMixins";

// Add this fo install vuetify-dialog
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.min.css";

import dayjs from "dayjs";
import "dayjs/locale/hu";
// import "dayjs/locale/nb";
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/nb.json"));
countries.registerLocale(require("i18n-iso-countries/langs/hu.json"));
countries.registerLocale(require("i18n-iso-countries/langs/lt.json"));

const languages = require("@cospired/i18n-iso-languages");
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/no.json"));
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/hu.json"));
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/lt.json"));

// tmp - remove traces of old login
memory.remove("user");
localStorage.removeItem("appSettingsAuthManager");

// THIS IS IMPORTANT TO INSTALL DIALOGS
// YOU NEED TO SEND VUETIFY INSTANCE INTO DIALOG CONTEXT
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

Vue.use(PortalVue);
Vue.use(VueObserveVisibility);

Vue.prototype.$EventBus = new Vue();

Vue.config.productionTip = false;

async function main() {
  apiOpsBff.connectWithStore(store, i18n);
  usersBff.initializeLogin(router, store);

  // detect default language
  const browserLanguage = usersBff.getBrowserLanguage();
  const storedLocale = await memory.getItemAsync("locale");
  const locale = storedLocale || browserLanguage || "en";
  i18n.locale = locale;
  dayjs.locale(
    locale && locale.length === 2 ? `${locale}_${locale.toUpperCase()}` : locale
  );

  const user = await memoryPrivate.getItemAsync("user");
  store.commit("user", user || false);

  const actionsData = require("@/app-data/actions.json");
  store.state.actionsData = actionsData;

  const lookups = require("@/app-data/lookups.json");
  store.state.lookups = lookups;

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");
}

if (process.env.TYPE === undefined || process.env.TYPE === "browser") {
  main();
} else {
  document.addEventListener("deviceready", main);
}
